export const footerData = [
  {
    id: 'p1',
    title: 'Dịch vụ',
    items: [
      {
        id: 'p1c1',
        name: 'Dịch vụ giao hàng',
        href: '/service/aha-delivery',
        external: false,
      },
      {
        id: 'p1c2',
        name: 'Thuê xe điện',
        href: 'https://thuexedien.ahamove.com/',
        external: true,
      },
      {
        id: 'p1c3',
        name: 'Dịch vụ xe tải',
        href: '/service/aha-truck',
        external: false,
      },
      {
        id: 'p1c4',
        name: 'Dịch vụ doanh nghiệp',
        href: '/service/cooperate',
        external: false,
      },
    ],
  },
  {
    id: 'p2',
    title: 'Công ty',
    items: [
      {
        id: 'p2c1',
        name: 'Về chúng tôi',
        href: '/about-us',
        external: false,
      },
      {
        id: 'p2c2',
        name: 'Tin tức',
        href: '/blog',
        external: false,
      },
      {
        id: 'p2c3',
        name: 'Tuyển dụng',
        href: '/recruitment',
        external: false,
      },
      // {
      //   id: 'p2c4',
      //   name: 'Chương trình AhaFuture2024',
      //   href: '/internship-program',
      //   external: false,
      // },
    ],
  },
  {
    id: 'p3',
    title: 'Khách hàng',
    items: [
      {
        id: 'p3c1',
        name: 'Khách hàng cá nhân',
        href: '/customer/merchant',
        external: false,
      },
      {
        id: 'p3c2',
        name: 'Khách hàng doanh nghiệp',
        href: '/customer/cooperate',
        external: false,
      },
      {
        id: 'p3c3',
        name: 'Cộng đồng khách hàng',
        href: '/customer-community',
        external: true,
      },
      {
        id: 'p3c4',
        name: 'Trung tâm hỗ trợ',
        href: '/customer/help-center',
        external: false,
      },
      {
        id: 'p3c5',
        name: 'Tài khoản tích hợp',
        href: '/intergration-account',
        external: false,
      },
    ],
  },
  {
    id: 'p4',
    title: 'Hỗ trợ',
    items: [
      {
        id: 'p4c1',
        name: 'Chính sách và điều khoản',
        href: '/policy',
        external: false,
      },
    ],
  },
  {
    id: 'p5',
    title: 'Tài xế',
    items: [
      {
        id: 'p5c1',
        name: 'Đăng ký tài xế mới',
        href: '/driver',
        external: false,
      },
      {
        id: 'p5c2',
        name: 'Cộng đồng tài xế',
        href: '/blog?category=shippers',
        external: false,
      },
      {
        id: 'p5c3',
        name: 'Cẩm nang tài xế',
        href: '/cam-nang-tai-xe',
        external: false,
      },
      {
        id: 'p5c4',
        name: 'Trung tâm hỗ trợ',
        href: '/driver/help-center',
        external: false,
      },
    ],
  },
  {
    id: 'p6',
    title: 'Đường dẫn nhanh',
    items: [
      {
        id: 'p6c1',
        name: 'Trung tâm hỗ trợ khách hàng',
        href: '/customer/help-center',
        external: false,
      },
      {
        id: 'p6c2',
        name: 'Trung tâm hỗ trợ tài xế',
        href: '/driver/help-center',
        external: false,
      },
      {
        id: 'p6c3',
        name: 'Nền tảng nhà phát triển',
        external: true,
        href: 'https://developers.ahamove.com',
      },
    ],
  },
];
